<template>
    <div>
      <ul class="picture-card">
        <li class="picture-card-item" v-for="(item,index) in list">
          <div style="height: 100%">
            <el-image  fit="cover"  src="item.url" style="card-img"></el-image>
          </div>
        </li>
      </ul>
    </div>
</template>

<script>
  export default {
    name: '',
    props:{
      list:{
        type:Array,
        default(){
          return []
        }
      }
    },
    data(){
      return{
        a: "../assets/mlslog5.jpg",
      }
    }
  }
</script>

<style scoped>

  .picture-card{
    margin: 0;
    display: inline;
    vertical-align: top;
    list-style: none;
    padding: 0;
  }

  .picture-card-item{
    width: 88px;
    height: 88px;
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    margin: 0 8px 8px 0;
    display: inline-block;
  }

  .card-img{
    height: 100%;
    width: 100%;
    object-fit: cover;

  }

</style>
