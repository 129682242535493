import Login from '../components/Login'
import Home from '../components/Home'
// import Welcome from '../components/welcome'
// import customerinfo from '../views/customerinfo/customerinfo'
// import User from '../views/user/user'
import Person from '../views/person/index'
// import Roles from '../views/roles/roles'
// import Menu from '../views/menu/menu'

export const routes = [
  // {
  //   path: '/',
  //   component: Home,
  //   redirect: '/worktable',
  //   children: [{ path: '/worktable', component: Welcome }]
  // },
  { path: '/login', component: Login },
  // { path: '/customer', component: Home, children: [{ path: '/customer/list', component: customerinfo }] },
  {
    path: '/user',
    component: Home,
    redirect: 'welcome',
    children: [
      // {
      //   path: '/user/cc', component: Welcome
      // }, {
      //   path: '/user/list', component: User
      // },
      {
        path: '/person', component: Person
      }
      // {
      //   path: '/roles/list', component: Roles
      // }, {
      //   path: '/menu/list', component: Menu
      // }
    ]
  }
]
