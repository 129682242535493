<template>
  <transition name="fade" v-if="isShow">
    <div class="modal-wrap">
      <div class="modal-content">
        <div class="modal-close" @click="hide">
          <i class="el-icon-close"></i>
        </div>
        <div class="modal-header">
          通知消息
        </div>
        <div class="modal-body">
          <div class="modal-card">
            <div class="card-body">
              <div class="card-meta">
                {{msgData.theme}}
              </div>
              <div>发布人：{{msgData.realname}} 发布时间： {{msgData.pubdate|dateformat}}</div>
              <el-divider></el-divider>
              <p v-html='msgData.value'></p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <el-button class="close-button" @click="hide">关 闭</el-button>
        </div>
      </div>
    </div>
    <!-- //success warn err default -->
    <!--    <div class="tip-box"-->
    <!--         :class="{'success':type =='success' ,'warn':type =='warn' ,'err':type =='err','default':type =='default'}" v-if="isShow">-->
    <!--      <div class="tip-box-content">-->
    <!--        {{content}}-->
    <!--      </div>-->
    <!--    </div>-->
  </transition>
</template>
<script>
  import { noteMsg } from '../../api/common'
  import { dateformat } from '../../util/common'

  export default {
    name: 'tip',
    props: {
      id: {
        type: String,
        default: '',
      }
    },
    data () {
      return {
        isShow: false,
        msgData:{},
      }
    },
    methods: {
      loadData(){
        noteMsg.msg({sguid: this.id}).then(r=>{
          if (r.code=="200"){
            this.msgData = r.data
            noteMsg.setReadFlag({sguid: this.id}).then(r=>{
              this.msgBus.$emit('topic',"")
            })
          }
        })
      },
      show () { //组件显示方法
        this.isShow = true
        //setTimeout(this.hide, this.timer)
        this.loadData()
      },
      hide () {//组件隐藏方法
        this.isShow = false
        this.remove() //当被挂载在create.js上是，this.remove()即执行comp.remove()，即从body上移除掉挂载的真实dom，并销毁虚拟dom
      }
    }
  }
</script>
<style lang="scss" scoped>

  .modal-wrap {
    background-color: rgba(0, 0, 0, 0.65);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow: auto;
    text-align: center;
    display: inline-block;
    box-sizing: border-box;
    outline: 0;


    .modal-content{
      position: relative;
      display: inline-block;
      vertical-align: middle !important;
      text-align: left;
      top: 0;
      box-sizing: border-box;
      width: 90vw;
      height: 90vh;
      border-radius: 0;
      background-color: white;
    }
    .modal-close{
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      right: 0;
      padding: 0;
      height: 56px;
      width: 56px;
      cursor: pointer;
      color: rgba(0,0,0,.45);
      background: transparent;
      transition: color .3s;
      i{
        font-size: 18px;
      }
    }
  .modal-close:hover{
    color: rgba(0,0,0,.75);
  }



    .modal-header{
      padding: 16px 24px;
      color: rgba(0,0,0,.65);
      background: #fff;
      border-bottom: 1px solid #e8e8e8;
      border-radius: 4px 4px 0 0;
      box-sizing: border-box;
      font-size: 16px;
    }

    .modal-body{
      height: calc(100% - 110px);
      overflow: auto;
      padding: 24px;
      font-size: 14px;
      line-height: 1.5;
      word-wrap: break-word;
      box-sizing: border-box;

      .modal-card{
        border: 1px solid #e8e8e8;

        .card-body{
          padding: 24px;
          zoom: 1;
          .card-meta{
            font-size: 16px;
            margin: -4px 0;
            margin-bottom: 8px;
          }
        }
      }
    }

    .modal-footer{
      padding: 10px 16px;
      text-align: right;
      background: transparent;
      border-top: 1px solid #e8e8e8;
      border-radius: 0 0 4px 4px;
      box-sizing: border-box;
      .close-button{
        padding: 0 15px;
        height: 32px;
        line-height: 32px;
        border-radius: 4px;
      }
    }
  }

  .modal-wrap:before{
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
    content: "";
  }

  .tip-box {
    position: fixed;
    left: 35%;
    top: 10px;
    font-size: 14px;
    width: 30%;
    padding: 20px 0;
    border-radius: 10px;
    letter-spacing: 1px;
    text-align: center;
    z-index: 9999;
    color: #fff;

  .tip-box-content {
    width: 90%;
    margin: 0 auto;
  }

  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
  }

  .success {
    background: #67C23A;
  }

  .warn {
    background: #E6A23C;

  }

  .err {
    background: #e24747;

  }

  .default {
    background: #909399;

  }
</style>
