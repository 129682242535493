<template>
    <el-select  :value="value"
                @change="handleChange">
       <el-option v-for="(item,index) in array" :key="index" :value="item" />
    </el-select>
</template>

<script>

  export default {
    model:{
      prop: 'value',
      event: 'change'
    },
    methods:{
     handleChange(val){
       this.$emit('change', val)
     },
    },
    props: {
      value:{
        type:String,
        default: ''
      },
      array:{
        type: Array,
        default(){
          return []
        }
      }

    }
  }
</script>

<style scoped>

</style>
