<template>
  <el-container class="home_container">
    <el-header >
      <div class="navbar">
<!--        LOGO-->
        <div class="logo">
           <el-image v-if="mainlogo" style="width:130px; height: 40px" fit="contain" :src="mainlogo"></el-image>
<!--          <el-image  fit="contain" style="height: 60px;width: 500px" :src="require('../assets/mlslog4.jpg')"></el-image>-->
          <span></span>
        </div>
<!--        应用菜单-->
        <div class="navbar_item">
<!--          <el-menu class="app_menu" default-active="1" mode="horizontal" active-text-color="#2362FB">-->
<!--            <el-menu-item index="1">-->
<!--              <i class="iconfont icondianpu-"/>-->
<!--              <span>专卖店管理</span>-->
<!--            </el-menu-item>-->
<!--          </el-menu>-->
        </div>
<!--        消息通知-->
<!--        <div class="bell_message">-->
<!--          <i class="el-icon-message-solid"></i>-->
<!--          <sup class="badge_content">0</sup>-->
<!--        </div>-->
        <header-notice></header-notice>

<!--        个人中心下拉菜单-->
        <el-dropdown  trigger="click" @command="handleCommand">
          <div class="user_container">
            <span class="avatar_circle">
              <img v-lazy="$options.filters.filterUserLazyImg(userinfo.img)"/>
            </span>
            <span class="el-dropdown-link">
              <i class="el-icon-caret-bottom mark"></i>
            </span>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="iconfont iconuser" command="info">个人信息</el-dropdown-item>
            <el-dropdown-item icon="iconfont icontuichu" divided command='out'>退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

    </el-header>
    <el-container>
      <el-aside :width="isCollapse ? '64px' : '200px'" class="aside">
        <el-menu :default-active= 'active_path' background-color="#001529" router :collapse="isCollapse" :collapse-transition="false" text-color="#BEBEC0">
          <menutree  :data="usermenu2"/>
        </el-menu>
<!--        <el-menu-->
<!--          :default-active= 'activePath'-->
<!--          router-->
<!--          :collapse="isCollapse"-->
<!--          :collapse-transition="false"-->
<!--          background-color="#001529"-->
<!--          text-color="#BEBEC0">-->
<!--&lt;!&ndash;          active-text-color="#409EFF"&ndash;&gt;-->
<!--          <el-submenu v-for="(item,index) in menulist" :key="index" :index="item.id + ''">-->
<!--            <template slot="title">-->
<!--              <i class="el-icon-location"></i>-->
<!--              <span>{{item.authname}}</span>-->
<!--            </template>-->
<!--            <el-menu-item v-for="(subitem,subindex) in item.children" :key="subindex" :index="'/' + subitem.path" @click="saveNavState('/' + subitem.path)">-->
<!--              <i class="el-icon-menu"></i>-->
<!--              <span slot="title">{{subitem.authname}}</span>-->
<!--            </el-menu-item>-->
<!--          </el-submenu>-->
<!--        </el-menu>-->
        <i class="iconfont iconshouqi1" :class="isCollapse ? 'collapseClose collapse_img' : 'collapse_img'" @click="toggleCollapse"/>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { logout } from '../api/common'
import menutree from './layout/menutree'
import { mapGetters } from 'vuex'
import HeaderNotice from './HeaderNotice'
import mlsmainlogo from '@/assets/mlsmainlogo.png'
import ddmainlogo from '@/assets/ddmainlog.png'
import { find_getbyfind } from '../api/common'

export default {
  name: 'home',
  data () {
    return {
      mainlogo: null,
      menulist: [],
      isCollapse: false,
      activePath: ''
    }
  },
  components: {
    menutree,
    HeaderNotice
  },
  mounted () {

  },
  computed: {
    ...mapGetters(['userinfo', 'active_path', 'usermenu']),
    usermenu2 () {
      var arr = this.usermenu
      return arr
      // if (this.userinfo.username === '17382198368') {
      //   return arr
      // }
      // var n = arr.filter(curr => {
      //   if (curr.id === '1321657858872795137') {
      //     return curr
      //   }
      // })
      // return n
    }
  },
  created () {        
      let userinfo = localStorage.getItem('userinfo')
      let id = JSON.parse(userinfo).id
      find_getbyfind({code:'feibiaophone', param:{cuscode: id}}).then(res=>{
        try{
          let brand = res.data.data.length > 0 ?  res.data.data[0].fc6 : null
          if (brand == "杜达"){
             document.title = "杜达定制"
             this.mainlogo = ddmainlogo
          }else{
            document.title = "米洛斯定制"
            this.mainlogo = mlsmainlogo
          }
        }catch (er){
          console.log(er)
        }
      })
  },
  methods: {
    handleCommand (command) {
      if (command === 'out') {
        this.loginOut()
      } else if (command === 'info') {
        this.$router.push('/person')
      }
    },
    loginOut () {
      logout().then(res => {
        localStorage.clear()
        this.$router.replace({ path: '/login' })
        location.reload()
        // this.$router.push('/login')
      })
    },
    getMenuList () {
      return [{
        id: '10',
        name: '首页',
        path: '/',
        icon: null,
        redirect: '/worktable',
        childrean: [{ id: '11', name: '首页', path: 'worktable' }]
      }, {
        id: '100',
        name: '专卖店',
        path: '/customer/list',
        icon: null
      }, {
        id: '101',
        name: '系统设置',
        path: null,
        icon: null,
        children: [
          {
            id: '104',
            name: '用户管理',
            path: '/user/list',
            icon: null
          }, {
            id: '1041',
            name: '角色管理',
            path: '/roles/list',
            icon: null
          }, {
            id: '1042',
            name: '菜单管理',
            path: '/menu/list',
            icon: null
          }
        ]
      }]
    },
    toggleCollapse () {
      this.isCollapse = !this.isCollapse
    }
  }
}
</script>

<style lang="less" scoped  type="text/less">

  /*隐藏文字*/
  .el-menu--collapse /deep/ .el-submenu__title span{
    display: none;
  }
  /*隐藏 > */
  /*.el-menu--collapse  .el-submenu__title .el-submenu__icon-arrow{*/
  /*  display: none;*/
  /*}*/

  .home_container{
    height: 100%;
  }

  .logo{
    margin-top: 8px;
    margin-left: 8px;
    // width: 200px;
    span{
      font-size: 20px;
    }
  }
  .app_menu{
    .el-menu-item{
      font-size: 16px;
      i{
        margin-right: 5px;
      }
    }
  }
  .el-header{
    background-color: white;
    background-image:url('../assets/mlslog6.jpg');
    background-size:-webkit-autofill;
    /*background-repeat:no-repeat;*/
    color: black;
    .navbar_item{
    }
  }
  .el-aside{
    background-color:#1C2A51;
    /*background-color: #001529;*/
    .el-menu{
      border-right: none;
    }
  }

  .el-main{
    background-color: #F5F6F9;

  }

  .navbar{
    display: flex;
    align-items: center;
    height: 100%;
    .user_container{
      display: flex;
      align-items: center;
      .avatar_circle{
        height: 32px;
        width: 32px;
        margin-right: 8px;
        img{
          height: 32px;
          width: 32px;
          border-radius: 50%;
          object-fit: fill;
        }
      }
      i{
        color: #9DA9C2;
      }
      i:hover{
        color: #2362FB;
      }
    }
    .navbar_item{
      flex: 1;
    }
  }

  .bell_message{
    margin-right: 30px;
    position: relative;
    .el-icon-message-solid{
      color: #9da9c2;
      cursor: pointer;
      font-size: 20px;
    }
    .el-icon-message-solid:hover{
      color: #2362FB;
    }
    .badge_content{
      background-color: #ff6161;
      border-radius: 10px;
      color: #fff;
      display: inline-block;
      font-size: 12px;
      height: 18px;
      line-height: 18px;
      padding: 0 6px;
      text-align: center;
      white-space: nowrap;
      border: 1px solid #fff;
      font-family: MicrosoftYaHei,Avenir,Helvetica,Arial,sans-serif;
      box-sizing: border-box;

      position: absolute;
      top: 0;
      right: 10px;
      -webkit-transform: translateY(-50%) translateX(100%);
      transform: translateY(-50%) translateX(100%);
    }
  }
  .aside{
    position :relative;
    span{
      /*color: #bebec0!important;*/
    }

    .collapse_img{
      height: 70px;
      width :70px;
      text-align: center;
      line-height: 70px;
      color: white;
      font-size: 15px;
      position : absolute;
      bottom :0px;
      right :0px;
    }
    .collapseClose{
      transform: rotateY(180deg);
    }
  }

</style>
