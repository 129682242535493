<template>
  <div class="login_container">
    <div class="login_top">
      <span>欢迎登录管理系统</span>
    </div>
    <div class="login_box">
      <el-form ref="loginFormRef" class="login_form" :model="loginForm" :rules="loginFormRules">
        <el-form-item prop="username">
          <el-input prefix-icon="iconfont iconuser" v-model="loginForm.username" @keyup.enter.native="login"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input prefix-icon="iconfont icon3702mima" v-model="loginForm.password" type="password" @keyup.enter.native="login"></el-input>
        </el-form-item>
        <el-form-item class="btns">
          <el-button type="primary" @click="login">登录</el-button>
          <el-button type="info" @click ="resetLoginForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="login_bottom">
      <span></span>
    </div>
  </div>
</template>

<script>
import { login } from '../api/common'
import { setToken } from '../util/common'
import { mapActions } from 'vuex'

export default {
  name: 'login',
  data () {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      loginFormRules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      }
    }
  },
  methods: {
    ...mapActions(['setuserinfo']),
    resetLoginForm () {
      this.$refs.loginFormRef.resetFields()
    },
    login () {
      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) return
        login(this.loginForm).then(res => {
          if (res.code === '200') {
            this.setuserinfo(res.data.userinfo)
            setToken(res.data.access_token)
            this.$router.push('/worktable')
          }
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .login_container {
    background-color: #1C77AC;
    background-image:
      url("../assets/loginbg3.png");
    background-size: 100% 100%;
    height: 100%;

  .login_box {
    height: 336px;
    width: 692px;
    background-image: url("../assets/logininfo.png");
    background-size: 100% 100%;
    /*border-radius: 3px;*/
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
  }
  }

  .btns {
    display: flex;
    justify-content: flex-end;
  }

  .login_form {
    position: absolute;
    top: 90px;
    left:265px;
    width: 380px;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .login_top{
    color: #AFC5D2;
    position: absolute;
    top: 0px;
    height: 45px;
    width: 100%;
    background-color: #14547A;
    line-height: 45px;
    padding-left: 10px;
    font-size: 12px;
  }
  .login_bottom{
    color: #0B3A58;
    position: absolute;
    bottom: 0px;
    height: 50px;
    width: 100%;
    background-color: #428EBA;
    line-height: 50px;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    justify-content: center;
  }
</style>
