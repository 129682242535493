<template>
  <div class="menutree">
    <label v-for="menu in data" :key="menu.index">
      <el-submenu :index="menu.id" v-if="menu.children">
        <template slot="title">
          <i class="el-icon-location"/>
          <span>{{menu.name}}</span>
        </template>
        <label>
            <menutree :data="menu.children"></menutree>
        </label>
      </el-submenu>
      <el-menu-item v-else :index="menu.url" @click="itemclick(menu)">
        <i class="el-icon-menu"/>
        <span slot="title">{{menu.name}}</span>
      </el-menu-item>
    </label>
  </div>
</template>

<script>
import menutree from '../layout/menutree'
import { mapActions } from 'vuex'

export default {
  name: 'menutree',
  data () {
    return {
      menu_data: {}
    }
  },
  components: {
    menutree: menutree
  },
  props: ['data', 'menuclick'],
  methods: {
    ...mapActions(['set_active_path']),
    itemclick (menu) {
      this.set_active_path(menu.url)
    }
  }
}

</script>

<style scoped>
  .el-menu-item{
    background-color: #1C2A51!important;
  }
  /deep/ .el-submenu__title{
    background-color: #1C2A51!important;
  }

  .el-menu-item:hover{
    background-color: #1A2D3F!important;
  }
  .el-menu-item.is-active{
    color: white!important;
    background-color: #2362FB!important;
  }

</style>
