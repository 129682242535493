<template>
  <div class="bell_message">
    <el-popover
      ref="popover"
      placement="top-end"
      trigger="click">
      <div slot="reference">
        <i class="el-icon-message-solid"></i>
        <sup class="badge_content" v-if="msgCount > 0">{{msgCount}}</sup>
      </div>
      <div class="pop-content">
        <div class="wrapper">
          <el-tabs class="tabs" v-model="activeName">
            <el-tab-pane :label="'通知(' + msgCount+ ')'" name="first">
              <div class="panel-wrapper">
                <div v-for="(item,index) in msgList" :key="index" class="row">
                  <div style="display: table-cell;color: #1890ff; vertical-align: middle;height: 30px;cursor: pointer;" @click="readNotice(index)">{{item.theme}}</div>
                  <div style="display: table-cell;color: #98a5ba; vertical-align: middle;height: 30px; text-align: right">{{item.pubdate|dateformat}}</div>
                </div>
              </div>
              <el-button class="panel-more">查看更多</el-button>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
import { websocketUrl } from '../config'
import store from '@/store/'
import { mapGetters } from 'vuex'
import { noteMsg } from '../api/common'

export default {
  name: 'HeaderNotice',
  data () {
    return {
      msgCount: 0,
      msgList: [],
      activeName: 'first'
    }
  },
  computed: {
    ...mapGetters(['userinfo'])
  },
  mounted () {
    this.loadData()
    this.initWebSocket()
    this.msgBus.$on("topic", ()=>{
      this.loadData()
    })
  },
  destroyed: function () { // 离开页面生命周期函数
    this.websock.close()
  },
  methods: {
    readNotice(index){
      this.$refs.popover.showPopper = false;
      this.$notice({id:this.msgList[index].sguid}).show()
    },
    loadData () {
      noteMsg.getUnreadTopicByUser().then(r => {
        if (r.code == '200') {
          this.msgList = r.data.msgList
          this.msgCount = r.data.msgListCount
        }
      })
    },
    initWebSocket: function () {
      // WebSocket与普通的请求所用协议有所不同，ws等同于http，wss等同于https
      var userId = this.userinfo.id
      var url = websocketUrl + userId
      this.websock = new WebSocket(url)
      this.websock.onopen = this.websocketOnopen
      this.websock.onerror = this.websocketOnerror
      this.websock.onmessage = this.websocketOnmessage
      this.websock.onclose = this.websocketOnclose
    },
    websocketOnopen: function () {
      console.log('WebSocket连接成功')
      //心跳检测重置
      //this.heartCheck.reset().start();
    },
    websocketOnerror: function (e) {
      console.log('WebSocket连接发生错误')
      this.reconnect()
    },
    websocketOnmessage: function (e) {
      console.log('-----接收消息-------', e.data)
      var data = eval('(' + e.data + ')') //解析对象
      if (data.cmd == 'topic') {
        this.msgBus.$emit('topic', e.data)
      } else if (data.cmd == 'user') {
        //用户消息
        this.loadData()
      }
      //心跳检测重置
      //this.heartCheck.reset().start();
    },
    websocketOnclose: function (e) {
      console.log('connection closed (' + e.code + ')')
      this.reconnect()
    },

    reconnect () {
      var that = this
      if (that.lockReconnect) return
      that.lockReconnect = true
      //没连接上会一直重连，设置延迟避免请求过多
      setTimeout(function () {
        console.info('尝试重连...')
        that.initWebSocket()
        that.lockReconnect = false
      }, 5000)
    },

  }

}
</script>

<style lang="less" scoped  type="text/less">

  .pop-content{
    .wrapper{
      min-height: 100px;
      min-width: 268px;

      /deep/ .el-tabs__item{
        color: #1890ff;
        line-height: normal;
        height: auto;
        padding: 12px 16px !important;
      }
      /deep/ .el-tabs__header{
        margin-bottom: 0;
      }
      /deep/ .el-tabs__active-bar{
        transform: none !important;
        background-color: #1890ff;
      }
      /deep/ .el-tabs__nav-wrap::after{
        height: 1px;
      }

      .panel-wrapper{
        margin: 12px 0;
        display: table;
        width: 100%;

        .row{
          display: table-row;
          width: 100%;
        }
        .row:hover{
          background-color: #E6F7FF;
        }
      }
      .panel-more{
        width: 100%;
        height: 30px;
        border: 1px dashed rgb(217, 217, 217);
      }
    }
  }

  .bell_message{
    margin-right: 30px;
    position: relative;
  .el-icon-message-solid{
    color: #9da9c2;
    cursor: pointer;
    font-size: 20px;
  }
  .el-icon-message-solid:hover{
    color: #2362FB;
  }
  .badge_content{
    background-color: #ff6161;
    border-radius: 10px;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    height: 18px;
    line-height: 18px;
    padding: 0 6px;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #fff;
    font-family: MicrosoftYaHei,Avenir,Helvetica,Arial,sans-serif;
    box-sizing: border-box;

    position: absolute;
    top: 0;
    right: 10px;
    -webkit-transform: translateY(-50%) translateX(100%);
    transform: translateY(-50%) translateX(100%);
  }
  }

</style>
