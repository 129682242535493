import Vue from 'vue'
import Vuex from 'vuex'
import layout from './model/layout'
import user from './model/user'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    userinfo: {}
  },
  mutations: {
    setuserinfo: (state, info) => {
      state.userinfo = info
      localStorage.setItem('userinfo', JSON.stringify(info))
    }
  },
  getters: {
    userinfo: state => {
      return '' || JSON.parse(localStorage.getItem('userinfo'))
    }
  },
  actions: {
    setuserinfo ({ commit }, info) {
      commit('setuserinfo', info)
    }
  },
  modules: {
    layout,
    user
  }
})

export default store
