<template>
    <div class="person_center">
      <div class="left">
        <div class="user_box">
          <img v-lazy="$options.filters.filterUserLazyImg(userForm.img)"/>
          <span>{{userForm.realname}}</span>
        </div>
        <ul class="nav_list">
          <li class="nav_list_item" :class="activeitem === 0 ? 'active':''" @click="changePage(0)">
            <i class="iconfont iconuser"/>
            <span>个人信息</span>
          </li>
          <li class="nav_list_item" :class="activeitem === 1 ? 'active':''" @click="changePage(1)">
            <i class="iconfont icon3702mima"/>
            <span>账号密码</span>
          </li>
          <li class="nav_list_item" :class="activeitem === 2 ? 'active':''" @click="changePage(2)">
            <i class="iconfont icon3702mima"/>
            <span>高级权限密码</span>
          </li>
        </ul>
      </div>
      <div class="right">
        <div v-if="activeitem === 0" class="user_info">
          <div class="head">
            <i class="iconfont iconuser"/>
            <span>个人信息</span>
          </div>
          <el-form class="user_info_form"  label-position="left" label-width="120px" :model="userForm">
            <el-form-item label="头像" >
              <div class="user_box_form">
                <img v-lazy="$options.filters.filterUserLazyImg(userForm.img)"/>
                <el-upload :action= "upfile.action" :headers="upfile.headers" :on-success="handleAvatarSuccess" :data="upfile.data" :multiple="false" :show-file-list="false" accept="image/png, image/jpeg, image/gif, image/jpg">
                  <span>更换头像</span>
                </el-upload>
              </div>
            </el-form-item>
            <el-form-item label="姓名">
              <el-input  placeholder="" v-model="userForm.realname"></el-input>
            </el-form-item>
            <el-form-item label="用户名">
              <el-input disabled  placeholder="" v-model="userForm.username"></el-input>
            </el-form-item>
            <el-form-item label="直属上级">
              <el-input disabled placeholder="" v-model="userForm.parent_name"></el-input>
            </el-form-item>
            <el-form-item label="性别">
              <el-input  placeholder="" v-model="userForm.sex"></el-input>
            </el-form-item>
            <el-form-item label="邮箱">
              <el-input  placeholder="" v-model="userForm.email"></el-input>
            </el-form-item>
            <el-form-item label="部门">
              <el-input disabled placeholder="" v-model="userForm.dept_name"></el-input>
            </el-form-item>
            <el-form-item label="岗位">
              <el-input  placeholder="" v-model="userForm.post"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="editinfo">保存</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div v-if="activeitem === 1" class="user_info">
          <div class="head">
            <i class="iconfont icon3702mima"/>
            <span>账号密码</span>
          </div>
          <el-form class="user_info_form" :model="psdForm" label-position="left" label-width="120px">
            <el-form-item label="原密码">
              <el-input  placeholder="" type="password" v-model="psdForm.oldpassword"></el-input>
            </el-form-item>
            <el-form-item label="新密码">
              <el-input  placeholder="" type="password" v-model="psdForm.newpassword"></el-input>
            </el-form-item>
            <el-form-item label="确认密码">
              <el-input  placeholder="" type="password" v-model="psdForm.checkpassword"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="editpsd">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div v-if="activeitem === 2" class="user_info">
          <div class="head">
            <i class="iconfont icon3702mima"/>
            <span>高级权限密码</span>
          </div>
          <el-form class="user_info_form" :model="advForm" label-position="left" label-width="120px">
            <el-form-item label="原密码">
              <el-input  placeholder="" type="password" v-model="advForm.oldpassword"></el-input>
            </el-form-item>
            <el-form-item label="新密码">
              <el-input  placeholder="" type="password" v-model="advForm.newpassword"></el-input>
            </el-form-item>
            <el-form-item label="确认密码">
              <el-input  placeholder="" type="password" v-model="advForm.checkpassword"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="editadvpsd">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
</template>

<script>
import { editadvauth, getUserInfo, updateImgByUse, updateInfoByUse, updatePasswordByUse, upload } from '../../api/common'
import { uploadUrl } from '../../config'
import { getToken } from '../../util/common'

export default {
  name: '',
  data () {
    return {
      activeitem: 0,
      userForm: {
      },
      psdForm: {
        oldpassword: '',
        newpassword: '',
        checkpassword: ''
      },
      advForm: {
        oldpassword: '',
        newpassword: '',
        checkpassword: ''
      },
      upfile: {
        action: uploadUrl,
        headers: { Authorization: 'Bearer ' + getToken() },
        data: { bizPath: 'user' }
      }
    }
  },
  methods: {
    changePage (index) {
      this.activeitem = index
    },
    editinfo () {
      updateInfoByUse(this.userForm).then(res => {
        if (res.code === '200') {
          this.$message.success('修改成功！')
        } else {
          this.$message.error(res.message)
        }
      })
    },
    editpsd () {
      updatePasswordByUse(Object.assign(this.psdForm, { username: this.userForm.username })).then(res => {
        if (res.code === '200') {
          this.$message.success('修改成功！')
        } else {
          this.$message.error(res.message)
        }
      })
    },
    editadvpsd(){
      editadvauth(this.advForm).then(r=>{
        if (r.code === '200') {
          this.$message.success('修改成功！')
        } else {
          this.$message.error(r.message)
        }
      })
    },
    customUpload (file) {
      upload(file).then(response => {
        console.log(response)
      })
    },
    handleAvatarSuccess (response) {
      updateImgByUse({ img: response.data.path, username: this.userForm.username }).then(res => {
        if (res.code === '200') {
          this.userForm.img = response.data.path
        }
      }
      )
    }
  },
  mounted () {
    getUserInfo().then(res => {
      this.userForm = res.data
    })
  }
}
</script>

<style lang="less" scoped type="text/stylus">

  .person_center{
    align-items: flex-start;
    width: 1180px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
  }

  .left{
    width: 300px;
    margin-right: 20px;
    background-color: #fff;
    min-height: 300px;
  }

  .right{
    flex: 1;
    background-color: #fff;
  }

  .nav_list{
    width: 100%;
    margin: 0px;
    padding: 0px;
  }

  .user_box{
    display: flex;
    padding: 15px 20px 20px;
    align-items: center;
    color: #666;
    img{
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 22px;
    }
  }

  .nav_list_item{
    height: 50px;
    color: #666;
    display: list-item;
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    cursor: pointer;
    i{
      margin-right: 10px;
    }
  }
  .nav_list_item:hover{
    background-color: #f1f5f8;
  }

  .active{
    color: #333;
    background-color: #f1f5f8;
  }

  .user_info{
    padding: 22px 25px;
    i{
      margin-right: 10px;
    }
  }
  .user_info_form{
    margin-top: 40px;
    width:500px;
    & /deep/ .el-input__inner{
             height: 34px;
             line-height: 34px;
             padding: 0 8px;
           }
  & /deep/ .el-button{
      color: #fff;
      background-color: #2362fb;
      border-color: #2362fb;
      height: 36px;
      }
  .el-button{
      width: 100%;
    }
  }
  .head{
    span{
      font-weight: 700;
      font-size: 16px;
    }
    i{
      color: #2362fb;
    }
  }

  .user_box_form{
    display: flex;
    align-items: center;
    img{
      width: 70px;
      height: 70px;
      border-radius: 50%;
      margin-right: 60px;
    }
    span{
      font-size: 12px;
      line-height: 1;
      color: #2362fb;
      border: 1px solid #2362fb;
      border-radius: 4px;
      padding: 8px;
      cursor: pointer;
    }
    span:hover{
      background-color: #2362fb;
      color: white;
    }
  }

</style>
