export const serverUrl = 'http://mls313.summererp.com:8210/'
export const websocketUrl = 'ws://mls313.summererp.com:8210/erp/websocket/'
export const uploadUrl = 'http://mls313.summererp.com:8210/erp/upload/'
export const mobileurl = 'http://mls313.summererp.com:8080/'
// //
// export const serverUrl = 'http://127.0.0.1:8210/'
// export const websocketUrl = 'ws://127.0.0.1:8210/erp/websocket/'
// export const uploadUrl = 'http://127.0.0.1:8210/erp/upload/'
// export const mobileurl = 'http://127.0.0.1:8080/'


// export const serverUrl = 'http://172.2.0.18:8210/'
// export const websocketUrl = 'ws://172.2.0.18:8210/erp/websocket/'
// export const uploadUrl = 'http://172.2.0.18:8210/erp/upload/'
// export const mobileurl = 'http://172.2.0.18:8080/'
