import Vue from 'vue'
import { Pagination, Timeline, TimelineItem, Popover, Loading, Image, DatePicker, Checkbox, Row, Col, Card, Divider, Scrollbar, Radio, Drawer, Upload, Tooltip, OptionGroup, MessageBox, Option, Dialog, Tabs, TabPane, TableColumn, Table, Select, Tree, DropdownMenu, DropdownItem, Button, Form, FormItem, Input, Message, Container, Header, Aside, Main, Menu, MenuItem, Submenu, MenuItemGroup, Dropdown } from 'element-ui'
Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.use(MenuItemGroup)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Tree)
Vue.use(Select)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Dialog)
Vue.use(Option)
Vue.use(OptionGroup)
Vue.use(Tooltip)
Vue.use(Upload)
Vue.use(Drawer)
Vue.use(Radio)
Vue.use(Scrollbar)
Vue.use(Divider)
Vue.use(Card)
Vue.use(Col)
Vue.use(Row)
Vue.use(Checkbox)
Vue.use(DatePicker)
Vue.use(Image)
Vue.use(Loading)
Vue.use(Popover)
Vue.use(Timeline)
Vue.use(TimelineItem)
Vue.use(Pagination)
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
