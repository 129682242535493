import {
  PopupManager
} from 'element-ui/lib/utils/popup'
import moment from 'moment'
import api from '../api/common'

export function uuid (len, radix) {
  var chars = '0123456789abcdefghijklmnopqrstuvwxyz'.split('')
  var uuid = []; var i
  radix = radix || chars.length

  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix]
  } else {
    // rfc4122, version 4 form
    var r

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
    uuid[14] = '4'

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | Math.random() * 16
        uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r]
      }
    }
  }
  return uuid.join('')
}
export function rowid () { return uuid(20, 36) }
export function getToken () {
  return localStorage.getItem('token')
}
export function setToken (val) {
  localStorage.setItem('token', val)
}

export function el_table_datetime_format (row, column) {
  var date = row[column.property]
  if (date == null || date === undefined) {
    return ''
  } ;
  return moment(date).format('YYYY-MM-DD HH:mm:ss')
}

// 判断字符是否为空的方法
export function isEmpty (obj) {
  if (typeof obj == 'undefined' || obj == null || obj == '') {
    return true
  } else {
    return false
  }
}

export function dateformat (date, format) {
  return moment(date).format(format || 'YYYY-MM-DD HH:mm:ss')
}

export function generateIndexRouter (data) {
  const indexRouter = [
    {
      path: '/',
      name: 'index',
      icon: '',
      component: resolve => require(['../components/Home'], resolve),
      redirect: '/worktable',
      children: [
        ...generateChildRouters(data)
      ]
    }
  ]
  return indexRouter
}

function generateChildRouters (data) {
  const routers = []
  for (const item of data) {
    const menu = {
      path: item.url,
      name: item.name,
      icon: item.icon,
      redirect: item.redirect,
      component: resolve => require(['@/' + item.component + '.vue'], resolve),
      internal_or_external: item.internal_or_external
    }
    if (item.children && item.children.length > 0) {
      menu.children = [...generateChildRouters(item.children)]
    }
    routers.push(menu)
  }
  return routers
}

/** 获取最大 z-index 的值 */
export function getMaxIndex () {
  return PopupManager.nextZIndex()
}

export function UnpackPrefix (str, sp) {
  const i = str.indexOf(sp)
  if (i < 0) return str
  return str.substring(0, i)
}

export function UnpackSuffix (str, sp) {
  const i = str.indexOf(sp)
  if (i < 0) return ''
  if (str.length == 1) return ''
  return str.substring(i + 1, str.length)
}

export function strToColumns (str) {
  let a = UnpackPrefix(str, '|')
  const b = UnpackSuffix(str, '|')
  const res = []
  while (a != '') {
    const c = {}
    const p = UnpackPrefix(a, ';')
    a = UnpackSuffix(a, ';')
    const s = UnpackPrefix(a, ';')
    a = UnpackSuffix(a, ';')
    c.field = p
    c.fieldname = s
    res.push(c)
  }
  return res
}

export function backFillObject (Target, TargetField, Source, SourceField, callback, changed) {
  const taArr = TargetField.split(';')
  const soArr = SourceField.split(';')
  for (let i = 0; i < taArr.length; i++) {
    Target[taArr[i]] = Source[soArr[i]]
    if (callback) {
      callback(taArr[i], Target)
    }
  }
  if (changed) {
    changed(null, Target)
  }
}
export function backFillObjectPlus (Target, Source, fieldStr, callback, changed) {
  let a = fieldStr
  while (a != '') {
    const t = UnpackPrefix(a, ';')
    a = UnpackSuffix(a, ';')
    const s = UnpackPrefix(a, ';')
    a = UnpackSuffix(a, ';')
    Target[t] = Source[s]
    if (callback) {
      callback(t, Target)
    }
  }
  if (changed) {
    changed(null, Target)
  }
}
export function fillDefaultValue (fieldStr, Target, vm) {
  let a = fieldStr
  while (a != '') {
    const t = UnpackPrefix(a, ';')
    a = UnpackSuffix(a, ';')
    const s = UnpackPrefix(a, ';')
    a = UnpackSuffix(a, ';')
    const scrtip = `target['${t}']=${s}`
    var x = new Function('target', 'vm', scrtip)
    x(Target, vm)
  }
}

export function paramsToFormData (obj) {
  const formData = new FormData()
  Object.keys(obj).forEach((key) => {
    if (obj[key] instanceof Array) {
      obj[key].forEach((item) => {
        formData.append(key, item)
      })
      return
    }
    formData.append(key, obj[key])
  })
  return formData
}
