import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { generateIndexRouter } from '../util/common'
import { routes } from './config'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes
})

// 挂导航守卫
router.beforeEach((to, form, next) => {
  // 获取用户菜单
  if (store.getters.usermenu.length === 0 && to.path != '/login') {
    store.dispatch('getFunction').then((res) => {
      let constRoutes = []
      constRoutes = generateIndexRouter(res.data)
      store.dispatch('updateRoutes', constRoutes).then(() => {
        // 动态添加路由
        router.addRoutes(constRoutes)
      })
    }).catch(err => {
      //登陆失败 重新登陆
      localStorage.removeItem("token")
      next({
        path: '/login',
        replace: true
      })
      location.reload()
    })
  }
  // 无令牌跳转到登录页，排除登录页
  const token = localStorage.getItem('token')
  if (!token && to.path !== '/login') {
    next({
      path: '/login',
      replace: true
    })
    location.reload()
  } else {
    next()
  }
})

export default router
