const layout = {
  stata: {
    active_path: ''
  },
  mutations: {
    set_active_path: (state, value) => { state.set_active_path = value }
  },
  getters: {
    active_path: (state) => {
      state.active_path = window.sessionStorage.getItem('activePath')
      return state.active_path
    }
  },
  actions: {
    set_active_path: ({ commit }, value) => {
      commit('set_active_path', value)
      window.sessionStorage.setItem('activePath', value)
    }
  }
}

export default layout
