/** 用户头像的占位图标 */
import { serverUrl } from '../config'
import moment from 'moment'

export function filterUserLazyImg (value) {
  return {
    src: serverUrl + 'erp/file/' + value,
    error: require('@/assets/img/head.png'),
    loading: require('@/assets/img/loading.gif')
  }
}

export function getImgSrc (value) {
  if (value && value.length > 4 && value.slice(0, 5) == 'blob:') { return value }
  return serverUrl + 'erp/file/' + value
}

export function dateformat (date, format) {
  if (date == null || date === undefined) {
    return ''
  } ;
  return moment(date).format(format || 'YYYY-MM-DD HH:mm')
}
