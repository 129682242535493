import { userFunction } from '../../api/common'
import { routes } from '../../router/config'

const user = {
  state: {
    function: [],
    routers: [],
    addroutes: []
  },
  mutations: {
    set_function: (state, value) => {
      state.function = value
    },
    SET_ROUTERS: (state, data) => {
      state.addroutes = data
      state.routers = routes.concat(data)
    }
  },
  getters: {
    usermenu: (state) => {
      return state.function
    },
    addroutes: (state) => {
      return state.addroutes
    }
  },
  actions: {
    getFunction: ({ commit }) => {
      return new Promise((resolve, reject) => {
        userFunction().then((res) => {
          if (res.code === '200') {
            commit('set_function', res.data)
            resolve(res)
          } else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    updateRoutes: ({ commit }, routes) => {
      return new Promise(resolve => {
        commit('SET_ROUTERS', routes)
        resolve()
      })
    }
  }
}
export default user
