import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import { Loading } from 'element-ui'

// 全局样式
import './assets/css/global.css'
import './assets/font/iconfont.css'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import VueHoverMask from './components/VueHoverMask'

import VueLazyload from 'vue-lazyload'
import * as filters from '@/util/filters.js'
import store from './store'
import notice from "./components/notice/index.js"

Vue.prototype.$notice= notice
Vue.prototype.msgBus = new Vue()
Vue.prototype.afterOrderProWorkFlowKey = 'process_002'
Vue.prototype.gDWorkFlowKey = 'process_004'
Vue.prototype.gD2WorkFlowKey = 'process720252'


import eCombox from './components/eCombox'
import ePictureCard from './components/ePictureCard'

Vue.prototype.loading = Loading
Vue.component('ElImageViewer', ElImageViewer)
Vue.component('VueHoverMask', VueHoverMask)
Vue.component('eCombox', eCombox)
Vue.component('ePictureCard', ePictureCard)

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require('@/assets/img/send_img.png'),
  loading: require('@/assets/img/loading.gif'),
  attempt: 1
})

// 注册全局过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false
/** 懒加载图片 */

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
