import axios from 'axios'
import {
  Message
} from 'element-ui'
import router from '@/router'
import { getToken } from './common'
import { serverUrl } from '../config'

// 请求域名
axios.defaults.baseURL = serverUrl

// 创建axios实例
const service = axios.create({
  baseURL: process.env.BASE_API, // api 的 base_url
  timeout: 15000 // 请求超时时间
})

service.interceptors.request.use(config => {
  const token = getToken()
  if (token) { config.headers.Authorization = 'Bearer ' + getToken() }
  return config
})
service.interceptors.response.use(response => {
  const res = response.data
  if (res.code === '443') {
    Message.error(res.message)
    return Promise.reject(res)
  } else if (res.code === '444') {
    localStorage.clear() // 删除用户信息
    Message.error('登录超时已过期，请重新登录！')
    router.replace({
      path: '/login'
    })
  }
  return res
}, err => {
  console.log(err)
  Message.error('网络错误，请稍后重试！')
}
)

export default service
